/* eslint-disable no-unused-vars */
import React, { Component } from "react"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styled from "styled-components"
import { withFirebase } from "./firebase"
import { getBlogUrl } from "../../lib/helpers"
import ResultFirstImage from "../../assets/result_bg_image.svg"
import InfoImage from "../../assets/info_image.svg"
import ReccImage from "../../assets/recomm_image.svg"
import OtherInfoImage from "../../assets/other_info.svg"
import DownloadImage from "../../assets/file_download.svg"
import { data } from "jquery"

const ResultDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
  overflow-x: hidden;
  border: none !important;
`

const DignoseDiv = styled.div`
  width: 100%;
  height: 100%;
`

const ResultHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noe Display;
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  font-style: italic;
  text-align: center;
`

const ResultImageDiv = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 560px) {
    height: 390px;
    width: 560px;
  }
  @media (max-width: 559px) {
    height: 276px;
    width: 375px;
  }
`

const MainDiagnose = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
  position: relative;
`

const InfoImageDiv = styled.div`
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 9%;
  @media (min-width: 560px) {
    top: -15%;
  }
  @media (max-width: 559px) {
    top: -12%;
  }
`

const MainDiagnoseInnerDiv = styled.div`
  margin-top: 2em;
  margin-bottom: 0.5em;
  width: 82.67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
`

const MainDiagnoseHeader = styled.div`
  width: 100%;
  font-family: Noe Display;
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
  padding-left: 3px;
  padding-bottom: 10px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid #262c47;
`

const MainDiagnoseText = styled.div`
  width: 100%;
  font-family: overpass;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  padding-top: 1em;
  padding-bottom: 1em;
`

const InfomationDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-top: 2em;
  position: relative;
`

const InfomationInnerDiv = styled.div`
  width: 82.67%;
  font-family: overpass;
  font-size: 18px;
  font-weight: 600;
  color: #19232d;
  text-align: left;
  margin-bottom: 1em;
`

const HandlungsempfehlungenDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
  color: #ffffff;
  position: relative;
  padding-bottom: 2em;
`

const RecImageDiv = styled.div`
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 99;
  @media (min-width: 560px) {
    top: -30px;
  }
  @media (max-width: 559px) {
    top: -30px;
  }
`

const HandlungsempfehlungenHeader = styled.div`
  margin-top: 1.8em;
  width: 85.33%;
  height: 30px;
  font-family: Noe Display;
  font-size: 22px;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
  padding-left: 10px;
  border: none;
  border-bottom: 2px solid #262c47;
`
const HandlungsempfehlungenHeaderSubText = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  width: 82.67%;
  font-family: Overpass;
  font-size: 18px;
  font-weight: 600;
  line-height: auto;
  text-align: left;
`

const HandlungsempfehlungenDetails = styled.div`
  width: 82.67%;
  margin-top: 1em;
  margin-bottom: 1em;
`

const HandlungsempfehlungenDetailsTitle = styled.div`
  width: 100%;
  font-family: Noe Display;
  font-size: 22px;
  font-weight: bold;
  line-height: 100%;
  padding-bottom: 0.5em;
`

const HandlungsempfehlungenDetailsText = styled.div`
  width: 100%;
  font-family: overpass;
  font-weight: regular;
  line-height: auto;
  text-align: left;
`

const RelevantArticleInfoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #19232d;
  position: relative;
  padding-bottom: 3em;
`

const OtherImageDiv = styled.div`
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 99;
  @media (min-width: 560px) {
    top: -2%;
  }
  @media (max-width: 559px) {
    top: -2.5%;
    left: 42%;
  }
`

const RelevantArticleInfoHeader = styled.div`
  margin-top: 3em;
  margin-bottom: 1em;
  width: 85.33%;
  border: none;
  border-bottom: 3px solid #262c47;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RelevantArticleInnerHeader = styled.div`
  width: 100%;
  font-family: Noe Display;
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
  text-align: left;
  border: none;
`

const RelevantArticleHeaderInnerText = styled.div`
  width: 100%;
  font-family: Overpass;
  font-size: 16px;
  font-weight: regular;
  line-height: auto;
  text-align: left;
  border: none;
  padding-top: 1em;
  padding-bottom: 1em;
`

const RelevantArticleDetails = styled.div`
  width: 82.67%;
  margin-top: 1em;
  margin-bottom: 1em;
  &:hover {
    cursor: pointer;
  }
`

const RelevantArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const RelevantArticleTitle = styled.div`
  width: 100%;
  font-family: Overpass;
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  padding-bottom: 0.7em;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const RelevantArticleIMGDiv = styled.div`
  margin-top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  @media (min-width: 560px) {
    width: 450px;
    height: 220px;
  }
  @media (max-width: 559px) {
    width: 310px;
    height: 179px;
  }
`

const FileDownloadDiv = styled.div`
  width: 99.5%;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbffa0;
  color: #19232d;
  position: relative;
`

const DownoadImageDiv = styled.div`
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 99;
  @media (min-width: 560px) {
    top: -20%;
  }
  @media (max-width: 559px) {
    top: -18%;
    left: 42%;
  }
`

const DowloadWord = styled.div`
  width: 82.67%;
  font-family: Noe Display;
  font-size: 22px;
  font-weight: bold;
  color: #19232d;
  outline: none;
  margin-bottom: 1em;
`

const DownloadButton = styled.button`
  width: 82.67%;
  height: 42px;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #19232d;
  border-color: #19232d;
  color: #ffffff;
  outline: none;
  &:hover {
    cursor: pointer;
    color: #19232d;
    background-color: #ffffff;
    border-color: #fbffa0;
  }
`

const QuizFooterDiv = styled.form`
  width: 99.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #19232d;
  color: #ffffff;
  position: relative;
`

const SubscribeTitle = styled.div`
  width: 82.67%;
  font-family: Noe Display;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  margin-top: 2em;
  margin-bottom: 1em;
`

const Input = styled.input`
  width: 82.67%;
  border: none;
  font-size: 14px;
  color: #ffffff;
  font-family: "overpass";
  border-bottom: 1px solid #ffffff;
  background-color: #19232d;
  outline: none;
  padding-left: 0px;
  background-color: transparent;
  padding-bottom: 0.8em;
  margin-bottom: 2em;
  ::placeholder {
    color: #ffffff;
  }
`

const SubscribeButton = styled.button`
  width: 82.67%;
  height: 42px;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #fbffa0;
  border-color: #fbffa0;
  color: #19232d;
  outline: none;
  margin-bottom: 2em;
  &:hover {
    cursor: pointer;
    color: #19232d;
    background-color: #ffffff;
    border-color: #19232d;
  }
`

const AffiliateDiv = styled.div`
  width: 100%;
  background-color: #fbffa1;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
`

const AffiliateLastDiv = styled.div`
  width: 100%;
  background-color: #fbffa1;
  padding-top: 30px;
  padding-bottom: 46px;
  position: relative;
`

const AffiliateImageDiv = styled.div`
  margin: 0 auto;
  @media (min-width: 560px) {
    width: 500px;
  }
  @media (max-width: 559px) {
    width: 315px;
  }
`

const AffiliateDesktopImage = styled.img`
  width: 100%;
  @media (min-width: 560px) {
    display: block;
  }
  @media (max-width: 559px) {
    display: none;
  }
`

const AffiliateMobileImage = styled.img`
  width: 100%;
  @media (min-width: 560px) {
    display: none;
  }
  @media (max-width: 559px) {
    display: block;
  }
`

const AffiliateContentDiv = styled.div`
  margin: 8px auto 0 auto;
  @media (min-width: 560px) {
    width: 500px;
  }
  @media (max-width: 559px) {
    width: 315px;
  }
`

const AffiliateHeadline = styled.h3`
  color: #19232d;
  font-family: Overpass;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: 560px) {
    font-size: 33px;
    line-height: 38px;
  }
  @media (max-width: 559px) {
    font-size: 30px;
    line-height: 35px;
  }
`

const AffiliateText = styled.p`
  color: #19232d;
  font-family: Overpass;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: 560px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: 559px) {
    font-size: 16px;
    line-height: 26px;
  }
`

const AffiliateButtonDiv = styled.div`
  width: 100%;
  @media (min-width: 560px) {
    margin-top: 19px;
  }
  @media (max-width: 559px) {
    margin-top: 23px;
  }
`

const AffiliateLink = styled.a`
  text-decoration: none;
  color: #19232d;
`

const AffiliateButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #19232d;
  color: #ffffff;
  border: none;
  font-family: Overpass;
  font-weight: bold;
  @media (min-width: 560px) {
    font-size: 20px;
    line-height: 31px;
  }
  @media (max-width: 559px) {
    font-size: 18px;
    line-height: 28px;
  }
`

const AffiliateTickOuter = styled.div`
  position: absolute;
  top: -10px;
  left: calc(50% - 61px);
  width: 122px;
  height: 34.9px;
  background-color: #fbffa0;
  border-radius: 45.3592px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const AffiliateTickInner = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-family: Overpass;
  font-weight: bold;
  font-size: 18.7693px;
  line-height: 29px;
  color: #19232d;
`

function AffiliateBox({ data, max, index }) {
  const { title, imageDesktop, imageMobile, text, buttonText, buttonURL } = data
  if (index === max - 1) {
    return (
      <AffiliateLastDiv>
        <AffiliateTickOuter>
          <AffiliateTickInner>Anzeige</AffiliateTickInner>
        </AffiliateTickOuter>
        <AffiliateImageDiv>
          <AffiliateDesktopImage src={imageDesktop.asset.fluid.src} alt={title} />
          <AffiliateMobileImage src={imageMobile.asset.fluid.src} alt={title} />
        </AffiliateImageDiv>
        <AffiliateContentDiv>
          <AffiliateHeadline>{title}</AffiliateHeadline>
          <AffiliateText>{text}</AffiliateText>
          <AffiliateButtonDiv>
            <AffiliateLink href={buttonURL} target="_blank" rel="noopener noreferrer">
              <AffiliateButton>{buttonText}</AffiliateButton>
            </AffiliateLink>
          </AffiliateButtonDiv>
        </AffiliateContentDiv>
      </AffiliateLastDiv>
    )
  }
  return (
    <AffiliateDiv>
      <AffiliateTickOuter>
        <AffiliateTickInner>Anzeige</AffiliateTickInner>
      </AffiliateTickOuter>
      <AffiliateImageDiv>
        <AffiliateDesktopImage src={imageDesktop.asset.fluid.src} alt={title} />
        <AffiliateMobileImage src={imageMobile.asset.fluid.src} alt={title} />
      </AffiliateImageDiv>
      <AffiliateContentDiv>
        <AffiliateHeadline>{title}</AffiliateHeadline>
        <AffiliateText>{text}</AffiliateText>
        <AffiliateButtonDiv>
          <AffiliateLink href={buttonURL} target="_blank" rel="noopener noreferrer">
            <AffiliateButton>{buttonText}</AffiliateButton>
          </AffiliateLink>
        </AffiliateButtonDiv>
      </AffiliateContentDiv>
    </AffiliateDiv>
  )
}

function randomize(MyArray) {
  return Math.floor(Math.random() * MyArray.length)
}

class Result extends Component {
  _initFirebase = false

  constructor(props) {
    super(props)

    this.state = {
      messages: "",
      loading: false,
      allQuestions: "",
      answeredQuestions: "",
      singleDiagnoseData: [],
      allDiagnosedData: [],
      email: "",
      error: ""
    }
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true
      this.fetchAnswerFromFirebase()
    }
  }

  componentDidMount() {
    this.firebaseInit()
  }

  componentDidUpdate() {
    this.firebaseInit()
  }

  componentWillUnmount() {
    this._initFirebase = false
  }

  // Callback used to fetch all the lifeStyle questions answered by the user
  getTotalNumberOfQuestions = data => {
    const allData = data.val()
    const questionsFromDatabase = Object.values(allData)
    this.setState({
      allQuestions: questionsFromDatabase.length,
      loading: false
    })
  }

  // Callback used to fetch all the  lifeStyle answers
  filterFetchedData = data => {
    const allData = data.val()
    const allAnswers = allData ? Object.values(allData) : []
    this.setState({
      messages: allAnswers,
      answeredQuestions: allAnswers.length,
      loading: false
    })
  }

  // Callback used to fetch the most relevant diagosis results
  fetchDiagnoseData = data => {
    const allData = data.val()
    this.setState({
      singleDiagnoseData: allData,
      loading: false
    })
  }

  // Callback used to fetch the all the diagosis results
  fetchAllDiagnosedResults = data => {
    const allData = data.val()
    this.setState({
      allDiagnosedData: allData,
      loading: false
    })
  }

  // Error handler used in fetchAnswerFromFirebase & fetchTotalNumberOfQuestions
  getError = err => {
    this.setState({ error: err })
  }

  // Get the all the user details from the Firebase Database
  fetchAnswerFromFirebase = () => {
    this.setState({ loading: true })
    return this.props.firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        // Get the total number of quiz questions shown to the user
        await this.props.firebase.lifeStyleQuestions(user.uid).on(
          "value",
          snapshot => this.getTotalNumberOfQuestions(snapshot),
          errObj => this.getError(errObj)
        )

        // Get the all the user answers to the lifeStyle questions
        await this.props.firebase.lifeStyleFilteredAnswers(user.uid).on(
          "value",
          snapshot => this.filterFetchedData(snapshot),
          errObj => this.getError(errObj)
        )

        // Get the all the user answers to the diagnose questions
        await this.props.firebase.topDiagnoseqRecommendation(user.uid).on(
          "value",
          snapshot => this.fetchDiagnoseData(snapshot),
          errObj => this.getError(errObj)
        )

        // Get the all the diagnose results diagnose questions
        await this.props.firebase.processedDiagnoseqResults(user.uid).on(
          "value",
          snapshot => this.fetchAllDiagnosedResults(snapshot),
          errObj => this.getError(errObj)
        )
      }
    })
  }

  handleFileDownload = postArray => {
    const linkArray = []
    postArray.map(relevantpost => {
      linkArray.push(
        `${globalHistory.location.origin}/${getBlogUrl(
          relevantpost.parenttag.slug.current,
          relevantpost.slug.current,
          relevantpost.articleTier
        )}`
      )
    })
  }

  handleEmailInput = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  handleEmailSubmit = async e => {
    e.preventDefault()
    const { email } = this.state
    if (!email) alert("Bitte geben Sie Ihre E-Mail-Adresse ein")
    else {
      const { msg, result } = await addToMailchimp(email, this.state)
      result !== "success" ? alert(msg) : alert(msg)
    }
  }

  singleDiagnoseResult = data => {
    return (
      <MainDiagnose>
        <InfoImageDiv style={{ backgroundImage: `url(${InfoImage})` }} />
        <MainDiagnoseInnerDiv>
          <MainDiagnoseHeader>{data.title}</MainDiagnoseHeader>
          <MainDiagnoseText>{data.description}</MainDiagnoseText>
        </MainDiagnoseInnerDiv>
      </MainDiagnose>
    )
  }

  mainDiagnoseInfo = data => (
    <InfomationDiv>
      <InfomationInnerDiv>
        {data._rawInformation
          ? data._rawInformation.map((batch, i) => {
              return <p key={i}> {batch.children[0].text}</p>
            })
          : ""}
      </InfomationInnerDiv>
    </InfomationDiv>
  )

  suggestionsInfo = data => {
    if ("actions" in data || "suggestions" in data) {
      return (
        <HandlungsempfehlungenDiv key="Handlungsempfehlungen">
          <RecImageDiv style={{ backgroundImage: `url(${ReccImage})` }} />
          <HandlungsempfehlungenHeader>Handlungsempfehlungen</HandlungsempfehlungenHeader>
          <HandlungsempfehlungenHeaderSubText>
            {data.actions ? data.actions : ""}
          </HandlungsempfehlungenHeaderSubText>
          {data.suggestions
            ? data.suggestions.map((batch, i) => {
                return (
                  <HandlungsempfehlungenDetails key={i}>
                    <HandlungsempfehlungenDetailsTitle>
                      {batch.question}
                    </HandlungsempfehlungenDetailsTitle>
                    <HandlungsempfehlungenDetailsText>
                      {batch.answer}
                    </HandlungsempfehlungenDetailsText>
                  </HandlungsempfehlungenDetails>
                )
              })
            : ""}
        </HandlungsempfehlungenDiv>
      )
    }
    return null
  }

  relevantArticleInfo = data => {
    return (
      <RelevantArticleInfoDiv key="relevantArticle">
        <OtherImageDiv style={{ backgroundImage: `url(${OtherInfoImage})` }} />
        <RelevantArticleInfoHeader>
          <RelevantArticleInnerHeader>
            Weitere Informationen über einen gesunden Schlaf findest du auf besserschlafen.de
          </RelevantArticleInnerHeader>
          <RelevantArticleHeaderInnerText>
            Folgende Artikel könnten dir besonders weiterhelfen.
          </RelevantArticleHeaderInnerText>
        </RelevantArticleInfoHeader>
        {data.relevantArticles
          ? data.relevantArticles.map((item, i) => {
              return (
                <RelevantArticleDetails key={i}>
                  <RelevantArticleLink
                    to={`/${getBlogUrl(
                      item.parenttag.slug.current,
                      item.slug.current,
                      item.articleTier
                    )}`}
                  >
                    <RelevantArticleTitle>{item.title}</RelevantArticleTitle>
                    <RelevantArticleIMGDiv
                      style={{ backgroundImage: `url(${item.mainImage.asset.fluid.src})` }}
                    />
                  </RelevantArticleLink>
                </RelevantArticleDetails>
              )
            })
          : ""}
      </RelevantArticleInfoDiv>
    )
  }

  filesDownload = data =>
    data.relevantArticles ? (
      <FileDownloadDiv>
        {data.relevantArticles ? (
          <DownoadImageDiv style={{ backgroundImage: `url(${DownloadImage})` }} />
        ) : (
          ""
        )}
        <DowloadWord>Diagnose als PDF runterladen</DowloadWord>
        <DownloadButton onClick={this.handleFileDownload}>DOWNLOADEN</DownloadButton>
      </FileDownloadDiv>
    ) : (
      ""
    )

  quizFooter = (
    <QuizFooterDiv onSubmit={this.handleEmailSubmit}>
      <SubscribeTitle>Bleib auf dem Laufenden </SubscribeTitle>
      <Input
        name="email"
        type="email"
        placeholder="Deine-E-Mail-Adresse"
        data-input-id="email"
        onChange={this.handleEmailInput}
        autoComplete="email"
      />
      <SubscribeButton type="submit">ANMELDEN</SubscribeButton>
    </QuizFooterDiv>
  )

  render() {
    const { loading, messages, singleDiagnoseData } = this.state
    const { affiliate = [] } = singleDiagnoseData
    return (
      <ResultDiv>
        {!messages && loading && <ResultHeader>Loading ...</ResultHeader>}
        {messages && (
          <DignoseDiv>
            {singleDiagnoseData.mainImage ? (
              <ResultImageDiv
                style={{
                  backgroundImage: `url(${singleDiagnoseData.mainImage.asset.fluid.src})`
                }}
              />
            ) : (
              <ResultImageDiv style={{ backgroundImage: `url(${ResultFirstImage})` }} />
            )}
            {this.singleDiagnoseResult(singleDiagnoseData)}
            {this.mainDiagnoseInfo(singleDiagnoseData)}
            {affiliate.length > 0 &&
              affiliate.map((singleAd, index) => (
                <AffiliateBox data={singleAd} key={index} max={affiliate.length} index={index} />
              ))}
            {this.suggestionsInfo(singleDiagnoseData)}
            {this.relevantArticleInfo(singleDiagnoseData)}
            {/* {this.filesDownload(singleDiagnoseData)} */}
            {this.quizFooter}
          </DignoseDiv>
        )}
      </ResultDiv>
    )
  }
}
export default withFirebase(Result)
